<template>
  <div class="container">
    <div class="row">
      <div class="col-xl-4">
        <h2>{{ this.term }}</h2>
        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          {{ userConf.date_range.startDate | moment }} -
          {{ userConf.date_range.endDate | moment }}
        </span>
      </div>
      <div class="col-xl-8 ">
        <UsStatsConfiguration></UsStatsConfiguration>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header h-auto border-0">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("analytics.trending_searches.item.timeline.title") }}
              </h3>
            </div>
            <div class="card-toolbar">
              <span
                :class="
                  this.search_history_item.table.length > 0
                    ? 'text-primary'
                    : 'text-danger'
                "
                class="font-weight-bolder font-size-h3"
              >
                <i
                  :class="
                    this.search_history_item.table.length > 0
                      ? 'text-primary'
                      : 'text-danger'
                  "
                  class="fa fa-search font-weight-bolder font-size-h3 mr-2"
                ></i>
                {{ parseInt(search_history_item.queries).toLocaleString() }}
                &nbsp;/&nbsp;
                <i
                  :class="
                    this.search_history_item.table.length > 0
                      ? 'text-primary'
                      : 'text-danger'
                  "
                  class="fa fa-users font-weight-bolder font-size-h3 mr-2"
                ></i>
                {{
                  parseInt(search_history_item.unique_queries).toLocaleString()
                }}
              </span>
            </div>
          </div>
          <!--begin::Body-->
          <div class="card-body p-0">
            <div v-if="this.loading">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                style="margin:auto;background:#fff;display:block;"
                width="200px"
                height="200px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
              >
                <g transform="translate(20 50)">
                  <circle cx="0" cy="0" r="6" fill="#7b0000">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.375s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(40 50)">
                  <circle cx="0" cy="0" r="6" fill="#c34c37">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.25s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(60 50)">
                  <circle cx="0" cy="0" r="6" fill="#fd7f64">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="-0.125s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
                <g transform="translate(80 50)">
                  <circle cx="0" cy="0" r="6" fill="#ffbea0">
                    <animateTransform
                      attributeName="transform"
                      type="scale"
                      begin="0s"
                      calcMode="spline"
                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                      values="0;1;0"
                      keyTimes="0;0.5;1"
                      dur="1s"
                      repeatCount="indefinite"
                    ></animateTransform>
                  </circle>
                </g>
              </svg>
            </div>
            <div
              v-else-if="
                graphData && graphData[0].data && graphData[0].data.length > 0
              "
            >
              <!--begin::Chart-->
              <apexchart
                class="card-rounded-bottom"
                id="chartItem"
                ref="chartItem"
                :options="chartOptions"
                :series="graphData"
                type="area"
                height="180"
                width="100%"
              ></apexchart>
            </div>
            <div v-else>
              <div
                class="d-flex align-items-center justify-content-between card-spacer flex-grow-1"
              >
                <div class="card-body text-center">
                  <div class="row">
                    <div class="col-sm-12">
                      <h2>
                        {{ $t("analytics.searches_without_results.no_result") }}
                      </h2>
                      <p>
                        {{
                          $t(
                            "analytics.searches_without_results.no_result_subtitle"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--end::Chart-->
          </div>
          <!--end::Body-->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("analytics.trending_searches.item.table.title") }}
              </h3>
            </div>
            <div class="card-toolbar"></div>
          </div>
          <div class="card-body">
            <div
              v-if="search_history_item.table.length > 0"
              id="kt_datatable_wrapper"
              class="dataTables_wrapper dt-bootstrap4 no-footer"
            >
              <div class="row">
                <div class="col-sm-12">
                  <v-data-table
                    dense
                    :headers="headers"
                    :items="search_history_item.table"
                    :options.sync="options"
                    :loading="loading"
                    :search="search"
                    class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                  >
                    <template v-slot:item.term="{ item }">
                      <router-link
                        :to="{
                          name: 'trending-item',
                          query: { id: item.elastic_item_id }
                        }"
                      >
                        {{ item.term }}
                      </router-link>
                    </template>
                    <template v-slot:item.result_setting_type_id="{ item }">
                      {{
                        item.result_setting_type_id == 1
                          ? "Autocomplete"
                          : "Search"
                      }}
                    </template>
                    <template v-slot:item.queries="{ item }">
                      {{ parseInt(item.queries).toLocaleString() }}
                    </template>
                    <template v-slot:item.unique_queries="{ item }">
                      {{ parseInt(item.unique_queries).toLocaleString() }}
                    </template>
                    <template v-slot:item.avg_position="{ item }">
                      {{
                        parseFloat(item.avg_position).toLocaleString("cs-CZ", {
                          maximumFractionDigits: 2
                        })
                      }}
                    </template>
                    <template v-slot:top>
                      <div class="row">
                        <div
                          class="d-flex align-items-center position-relative my-1"
                        >
                          <span
                            class="svg-icon svg-icon-1 position-absolute ms-6"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <rect
                                opacity="0.5"
                                x="17.0365"
                                y="15.1223"
                                width="8.15546"
                                height="2"
                                rx="1"
                                transform="rotate(45 17.0365 15.1223)"
                                fill="black"
                              ></rect>
                              <path
                                d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                fill="black"
                              ></path>
                            </svg>
                          </span>
                          <input
                            type="text"
                            v-model="search"
                            :placeholder="
                              $t(
                                'analytics.trending_searches.item.table.search_field'
                              )
                            "
                            class="form-control form-control-solid w-250px ps-14"
                          />
                        </div>
                        <div
                          class="d-flex align-items-center position-relative my-1 ml-2"
                        >
                          <v-chip-group
                            multiple
                            v-model="activeFilters.object_type"
                            class="py-2"
                          >
                            <template v-for="item in filters.object_type">
                              <v-chip
                                :key="`${item}`"
                                :value="item"
                                :filter="
                                  activeFilters.object_type.includes(item)
                                "
                                :ripple="false"
                              >
                                {{ item }}
                              </v-chip>
                            </template>
                          </v-chip-group>
                        </div>
                      </div>
                    </template>
                  </v-data-table>
                </div>
              </div>
            </div>
            <div v-else class="text-center">
              <div class="row">
                <div class="col-sm-12">
                  <h2 class="m-5">
                    {{ $t("analytics.trending_searches.item.no_result") }}
                  </h2>
                  <fixit_edit_dialog
                    v-if="
                      !loading && this.search_history_item.fixits.length === 0
                    "
                    :term="this.term"
                    disabled="true"
                    :callback="reloadData"
                    :buttontitle="
                      $t('search_settings.fixit.buttons.fix_this_problem')
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="this.search_history_item.fixits.length > 0">
      <div class="col-xl-12">
        <div class="card card-custom gutter-b">
          <div class="card-header flex-wrap py-3">
            <div class="card-title">
              <h3 class="card-label">
                {{ $t("analytics.trending_searches.item.table_fixit.title") }}
              </h3>
            </div>
            <div class="card-toolbar">
              <fixit_edit_dialog disabled="true" :callback="reloadData" />
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-sm-12">
                <v-data-table
                  dense
                  :headers="fixit_headers"
                  :items="this.search_history_item.fixits"
                  :options.sync="options"
                  :loading="loading"
                  :search="search"
                  :loading-text="
                    $t('analytics.trending_searches.item.table_fixit.loading')
                  "
                  class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                >
                  <template v-slot:item.term="{ item }" class="align-middle">
                    <div class="fixit-steps">
                      <span
                        class="btn btn-danger text-left btn-arrow btn-arrow-right"
                        >{{ $t("search_settings.fixit.from_this_query")
                        }}<br /><strong>{{ item.term }}</strong></span
                      >
                      <span
                        class="btn btn-success text-left btn-arrow btn-arrow-right"
                        >{{ $t("search_settings.fixit.to_query") }}<br /><strong
                          >{{ item.catalog_term }}</strong
                        ></span
                      >
                    </div>
                  </template>
                  <template
                    v-slot:item.executed="{ item }"
                    class="align-middle"
                  >
                    <div class="text-center">{{ item.executed }}</div>
                  </template>
                  <template v-slot:item.actions="{ item }" class="align-middle">
                    <b-dropdown text="Actions">
                      <b-dropdown-item @click.prevent="editItem(item)">
                        {{ $t("common.edit") }}
                      </b-dropdown-item>
                      <b-dropdown-item @click.prevent="deleteItem(item)">
                        {{ $t("common.delete") }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">
                      {{ $t("common.reset") }}
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { LOAD_SEARCH_HISTORY_ITEM } from "@/core/services/store/search_history.module";
import moment from "moment";
import { REMOVE_CONTENT_CLASSNAME } from "@/core/services/store/htmlclass.module";
import { DELETE_FIXIT, PULL_FIXIT } from "@/core/services/store/fixit.module";
import Swal from "sweetalert2";
import fixit_edit_dialog from "@/view/pages/fixit/edit_dialog";
import UsStatsConfiguration from "@/view/content/widgets/USStatsConfiguration.vue";

export default {
  components: { UsStatsConfiguration, fixit_edit_dialog },
  data() {
    return {
      chartOptions: {
        chart: {
          type: "bar",
          height: 180,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: false
          },
          sparkline: {
            enabled: true
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2
        },
        xaxis: {
          type: "datetime"
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          x: {
            show: true,
            format: "dd. MM h:00:00",
            formatter: undefined
          },
          y: {
            formatter: function(val) {
              return parseInt(val).toLocaleString();
            }
          }
        }
      },
      options: {},
      search: "",
      filter: "search",
      filters: { object_type: [] },
      activeFilters: [{ object_type: [] }],
      term: this.$route.query.term,
      headers: [
        { text: "#", value: "row" },
        {
          text: this.$t(
            "analytics.trending_searches.item.table.headers.result_type"
          ),
          value: "result_setting_type_id"
        },
        {
          text: this.$t(
            "analytics.trending_searches.item.table.headers.object_type"
          ),
          value: "object_type",
          filter: value => {
            return this.activeFilters.object_type
              ? this.activeFilters.object_type.includes(value)
              : true;
          }
        },
        {
          text: this.$t("analytics.trending_searches.item.table.headers.item"),
          value: "term"
        },
        {
          text: this.$t(
            "analytics.trending_searches.item.table.headers.position_avg"
          ),
          value: "avg_position"
        },
        {
          text: this.$t(
            "analytics.trending_searches.item.table.headers.queries"
          ),
          value: "queries"
        },
        {
          text: this.$t(
            "analytics.trending_searches.item.table.headers.unique_queries"
          ),
          value: "unique_queries"
        }
      ],
      fixit_headers: [
        {
          text: this.$t(
            "analytics.trending_searches.item.table_fixit.headers.fixit_definition"
          ),
          value: "term"
        },
        {
          text: this.$t(
            "analytics.trending_searches.item.table_fixit.headers.rule_executed"
          ),
          value: "executed"
        },
        {
          text: this.$t(
            "analytics.trending_searches.item.table_fixit.headers.actions"
          ),
          value: "actions",
          sortable: false
        }
      ]
    };
  },
  watch: {
    currentUserConfiguration: {
      handler: function() {
        this.reloadData();
      },
      deep: true
    },
    changeProject() {
      this.reloadData();
    },
    graphData() {
      this.initFilters();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    userConf() {
      return this.currentUserConfiguration.stats;
    },
    graphData() {
      return [
        {
          name: this.$t("analytics.trending_searches.item.timeline.data_title"),
          data: this.search_history_item.timeline.data
        }
      ];
    },
    ...mapState({
      search_history_item: state =>
        state.searchHistory.tables.search_history_item,
      loading: state => state.searchHistory.loading
    }),
    ...mapGetters(["currentProject", "currentUserConfiguration"])
  },
  filters: {
    moment: function(date) {
      return moment(date).format("DD. MM YYYY");
    }
  },
  destroyed() {
    this.$store.dispatch(REMOVE_CONTENT_CLASSNAME, "content-loading");
  },
  mounted() {
    this.reloadData();
  },
  methods: {
    updateOptions() {
      if (this.search_history_item.table.length === 0 && this.$refs.chartItem) {
        this.$refs.chartItem.updateOptions({ colors: ["#F64E60"] });
      }
    },
    initFilters() {
      for (let col in this.filters) {
        this.filters[col] = this.search_history_item.table
          .map(d => {
            return d[col];
          })
          .filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
      }

      this.activeFilters = Object.assign({}, this.filters);
    },
    editItem(item) {
      this.$store.dispatch(PULL_FIXIT, item.id);
    },
    deleteItem(item) {
      Swal.fire({
        title: this.$t("common.swal.title.areyousure"),
        text: `You are want delete \`${item.term}\`. Are you sure?`,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: this.$t("common.swal.button.delete")
      }).then(result => {
        if (result.value) {
          this.$store.dispatch(DELETE_FIXIT, item.id).then(() => {
            this.reloadData();
            Swal.fire(this.$t("common.swal.response.deleted"), "", "success");
          });
        }
      });
    },
    reloadData() {
      const data = [];
      if (
        this.userConf.result_object === "search" ||
        this.userConf.result_object === "all"
      ) {
        data.push("result_setting_type_id[]=2");
      }
      if (
        this.userConf.result_object === "autocomplete" ||
        this.userConf.result_object === "all"
      ) {
        data.push("result_setting_type_id[]=1");
      }

      if (this.userConf.date_range.startDate) {
        data.push(
          `start=${moment(this.userConf.date_range.startDate).format(
            "yyyy-MM-DD"
          )}`
        );
      }
      if (this.userConf.date_range.endDate) {
        data.push(
          `end=${moment(this.userConf.date_range.endDate).format("yyyy-MM-DD")}`
        );
      }
      if (this.userConf.language) {
        data.push(`language=${this.userConf.language}`);
      }
      if (this.term) {
        data.push(`term=${this.term}`);
      }

      this.$store.dispatch(LOAD_SEARCH_HISTORY_ITEM, data).then(() => {
        this.updateOptions();
      });
    }
  }
};
</script>
